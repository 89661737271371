import React, { useState, useEffect } from "react";
import "./TaskStatus.css";
import { pauseIcon } from "../../assets/svg/Icons";
import { capitalizeFirstLetter } from "../../util/util";
import { BeatLoader } from "react-spinners";
import checkPermission from "~/helpers/checkPermission";
import stringToSlug from "~/helpers/stringToSlug";
import CheckPermission from "../CheckPermission";

function TaskStatus(props) {
  const { taskId, status, statuses, changeTaskValue, disabled } = props;

  const [showOptions, setShowOptions] = useState(false);
  const [showConcluded, setShowConcluded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (status.default_name === "CONCLUÍDO") setShowConcluded(() => true);
  }, [status]);

  const toggleShowOptions = () => setShowOptions((showOptions) => !showOptions);

  const handleStatusChange = async (status) => {
    setLoading(true);
    try {
      const res = await changeTaskValue("status", status, taskId, true);
    } catch (err) {
      console.log("err:", err);
    }
    // if (res && status.name === "CONCLUÍDO") {
    //   setShowConcluded(() => true);
    // }
    toggleShowOptions();
    setLoading(false);
  };

  return (
    <>
      {showConcluded ? (
        <div className="concluded-status">
          <span className="pl-2 mr-3">{pauseIcon}</span>
          <div className="d-flex align-items-center flex-column ">
            <p>Status</p>
            <p>Concluído</p>
          </div>
        </div>
      ) : (
        <div>
          <CheckPermission
            slug={[
              "modificar-status-exceto-concluido",
              "modificar-status-concluido",
            ]}
            logical="and"
          >
            <button
              className="w-100  p-0"
              disabled={disabled}
              onClick={toggleShowOptions}
            >
              <div
                className={`${
                  disabled ? "row-status-disabled" : "row-status"
                } mb-2 d-flex justify-content-between w-100 p-2`}
              >
                <div className="ml-0 d-flex align-items-center justify-content-center">
                  <span className="pl-2 mr-3">{pauseIcon}</span>
                  <span style={{ fontSize: "12px" }}>
                    Status <br />
                    {!loading ? (
                      capitalizeFirstLetter(status.name)
                    ) : (
                      <BeatLoader color="#FFF" size={5} />
                    )}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <span>Alterar Status {">"}</span>
                </div>
              </div>
            </button>
          </CheckPermission>

          {showOptions ? (
            <div className="dropup-content">
              {statuses.map((status) => {
                if (
                  (!checkPermission("modificar-status-concluido") &&
                    stringToSlug(status.name) === "concluido") ||
                  (!checkPermission("modificar-status-exceto-concluido") &&
                    stringToSlug(status.name) !== "concluido")
                )
                  return <></>;

                return (
                  <p key={status.id} onClick={() => handleStatusChange(status)}>
                    {capitalizeFirstLetter(status.name)}
                  </p>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}

export default TaskStatus;
