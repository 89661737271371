import { EditTwoTone } from "@ant-design/icons";
import moment from "moment";
import React, { useState } from "react";
import "./ResourceInputHistoryCard.css";
import { Popover } from "antd";

import ResourceInputModal from "../../components/ResourceInputModal";

function ResourceInputHistoryCard({ data, handleAddResourceInput }) {
  const [modalShow, setModalShow] = useState(false);
  const [resourceInputInfo, setResourceInputInfo] = useState(false);

  return (
    <div className="card-container">
      <ResourceInputModal
        open={modalShow}
        onCancel={() => {
          setModalShow(false);
        }}
        initialEditingValues={resourceInputInfo}
        onSuccess={handleAddResourceInput}
      />
      <div className="card-left-side">
        <div className="card-line"></div>
        <div
          className="card-circle"
          style={{
            backgroundColor: `hsl(${Math.random() * 360}, 75%, 55%)`,
          }}
        ></div>
      </div>
      <div className="paper-card">
        <div className="paper-card-info">
          <span className="card-row-info">
            <b>ID: </b>
            {data.id}
          </span>
          <span className="card-row-info">
            <b>Nome: </b>
            {data.resource_name}
          </span>
          <span className="card-row-info">
            <b>Quantidade: </b>
            {data.quantity}
          </span>

          <span className="card-row-info">
            <b>Documento:</b> {data.invoice ?? " -"}
          </span>
          <span className="card-row-info">
            <b>Responsável:</b> {data.username}
          </span>
        </div>
        <div className="paper-card-info">
          <span className="card-row-info">
            <b>Criação:</b> {moment(data.created_at).format("DD/MM/YYYY")}
          </span>
          <span className="card-row-info">
            <b>Data Entrada:</b>{" "}
            {moment(data.input_date ?? data.created_at).format("DD/MM/YYYY")}
          </span>
          <span className="card-row-info">
            <b>Última atualização:</b>{" "}
            {moment(data.updated_at).format("DD/MM/YYYY - HH:mm:ss")}
          </span>

          <span className="card-row-info">
            <b>Descrição:</b> {data.description ?? " -"}
          </span>
        </div>
        <div className="paper-card-info">
          <h5>Campos Adicionais:</h5>
          <div
            style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
          >
            {data.additional_fields?.map((item, i) => (
              <div
                key={item.id}
                style={{
                  margin: "5px",
                  borderRadius: "5px",
                  border: "2px solid var(--blue-color)",
                  minWidth: "100px",
                  paddingInline: "7px",
                }}
              >
                <b>{item.resources_field?.name}</b>: {item.value}
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex">
          <div
            onClick={() => {
              setResourceInputInfo({
                id: data.id,
                resource_id: data.resource_id,
                name: data.resource_name,
                additional_fields: data.additional_fields.map((item) => ({
                  id: item.id,
                  name: item.resources_field?.name,
                  value: item.value,
                })),
                description: data.description,
                quantity: String(data.quantity),
                invoice: data.invoice,
                input_date: data.input_date,
              });
              setModalShow(true);
            }}
            style={{
              display: "flex",
              cursor: "pointer",
              alignItems: "center",
            }}
          >
            <Popover placement="top" content="Editar Entrada">
              <EditTwoTone style={{ fontSize: "24px" }} />
            </Popover>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResourceInputHistoryCard;
