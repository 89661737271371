import React, { useContext, useEffect, useState } from "react";
import { ResourcesRoutes } from "~/http/routes";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "~/redux/Selectors";
import HistoryCard from "./components/ResourceInputHistoryCard";
import Breadcrumb from "~/components/Breadcrumb/Breadcrumb";
import { ToastContext } from "~/providers/ToastProvider";
import { Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

function ResourceInputHistory() {
  const { companyId } = useSelector(userSelector);
  const { resourceId } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [resourceInputHistoryList, setResourceInputHistoryList] = useState([]);
  const { setToast } = useContext(ToastContext);

  const handleAddResourceInput = () => {
    setReload(!reload);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const res = await ResourcesRoutes.getResourcesInputByResourceId(
          companyId,
          resourceId,
        );

        setResourceInputHistoryList(res.data.data);
      } catch (error) {
        console.error(error);
        setToast({
          type: "error",
          title: "Erro",
          message: "Um erro ocorreu durante a listagem dos recursos.",
          show: true,
          autohide: true,
        });
      }
    };

    getData();
    setIsLoading(false);
  }, [companyId, resourceId, reload]);

  return (
    <div>
      <Breadcrumb title="Recursos" routeToBack="/home/resources" />

      <h3 className="my-5">{`Entradas do Recurso: ${
        resourceInputHistoryList[0]?.resource_name ?? ""
      }`}</h3>

      <div
        className="d-flex flex-column"
        style={{
          marginBottom: "40px",
          marginTop: "20px",
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <LoadingOutlined style={{ fontSize: "4em" }} />
        ) : resourceInputHistoryList.length === 0 ? (
          <Empty description="Nenhuma entrada realizada" />
        ) : (
          resourceInputHistoryList?.map((cardData) => (
            <HistoryCard
              key={cardData.id}
              data={cardData}
              handleAddResourceInput={handleAddResourceInput}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default ResourceInputHistory;
