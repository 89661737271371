import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./CardDetails.css";
import imagePlaceHolder from "../../assets/avatar-placeholder.png";
import TooltipImage from "../TooltipImage/TooltipImage";
import moment from "moment";

function CardDetails(props) {
  const { data } = props;

  return (
    <>
      <Row className="pl-0">
        <Col>
          {data.priority && data.priority.name !== "NENHUM" ? (
            <p
              style={{
                backgroundColor: data.priority.color,
                borderRadius: "30px",
                width: "110px",
                margin: "0",
                textAlign: "center",
                color: "white",
                fontSize: "12px",
                height: "20px",
              }}
            >
              {data.priority.name}
            </p>
          ) : (
            ""
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {/* <p className="m-0 mt-2 font-weight-bold">{data.name}</p> */}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex mt-1">
            <strong>{`ID: ${data.id || "N/A"}`} </strong>
          </div>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col>
          <label className="detail-label">Serviço</label>
          <Row className="ml-0">
            <p>{data.service?.name || "N/A"}</p>
          </Row>
        </Col>
        <Col>
          <label className="detail-label">Setor</label>
          <Row className="ml-0">
            <p>{data.sector?.name || "N/A"}</p>
          </Row>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col>
          <label className="detail-label">Início</label>
          <Row className="ml-0">
            <p>
              {(data.start_date &&
                moment(data.start_date, "DD/MM/YYYY HH:mm:ss").format(
                  "DD/MM/YYYY - HH:mm",
                )) ||
                "N/A"}
            </p>
          </Row>
        </Col>

        <Col>
          <label className="detail-label">Responsável</label>
          <Row className="ml-0">
            <Image
              className="mr-2"
              width="25"
              height="25"
              style={{
                cursor: "pointer",
                objectFit: "cover",
                objectPosition: "center",
                border: "2px solid rgb(24, 144, 255)",
              }}
              src={
                data.responsible.photo
                  ? "data:image/png;base64, " + data.responsible.photo
                  : imagePlaceHolder
              }
              roundedCircle
            />
            <p className="m-0">{data.responsible.name}</p>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <label className="detail-label">Entrega</label>
          <Row className="ml-0">
            <p>
              {(data.due_date &&
                moment(data.due_date, "DD/MM/YYYY HH:mm:ss").format(
                  "DD/MM/YYYY - HH:mm",
                )) ||
                "N/A"}
            </p>
          </Row>
        </Col>
        <Col>
          <label className="detail-label">Inventário</label>
          <p>{data.inventory?.name || "N/A"}</p>
        </Col>
      </Row>

      <Row className="mb-2">
        <Col>
          <label className="detail-label">Observadores</label>
          <Row className="ml-0 ">
            {data.observers.length > 0
              ? data.observers.map((observer) => (
                  <TooltipImage
                    key={observer.id}
                    src={
                      observer.photo
                        ? "data:image/png;base64, " + observer.photo
                        : imagePlaceHolder
                    }
                    tip={observer.name}
                    placement="right"
                    width="25"
                    height="25"
                  />
                ))
              : "N/A"}
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <label className="detail-label">Descrição</label>
          <p className="card-detail-description">{data.description || "N/A"}</p>
        </Col>
      </Row>

      <Row></Row>

      {/* <Row>
        <Col>
          <label className="detail-label">Localização</label>
          <p>{data.localization || "N/A"}</p>
        </Col>
      </Row> */}
    </>
  );
}

export default CardDetails;
